@import "../../variables";

.app {
  text-align: center;
}

.app-logo {
  height: 35vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: $color-surface-4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color-brand;
}

.app-link {
  color: $color-info;
  margin-top: 10px;
}

.button-bar {
  display: flex;
}

.button-bar {
  margin-top: 50px;
}

.text {
  margin-top: 30px;
  margin-bottom: 30px;
}

.help-links {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.msg-toast {
  position: fixed;
  top: 0;
  right: 20px;
}

img {
  margin-bottom: 20px;
}

p {
  margin: 0px;
}

.app-button {
  margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}